import React from 'react';
import './App.css';

function App() {
  
  const productLink = "https://www.amazon.co.uk/Three-Billy-Goats-Gruff-Flip-Up/dp/190455072X?crid=2SU5Z5Q214J6J&keywords=3+billy+goats+gruff+book&qid=1699654242&sprefix=3+billy%2Caps%2C108&sr=8-3&linkCode=ll1&tag=triptrapping-21&linkId=9ae456d649eabab25b0a9539a689a1a7&language=en_GB&ref_=as_li_ss_tl";
  
  return (
    <div className="App">
      <header className="App-header">

        <p>TripTrapping is a sandpit W.I.P. site that will either follow a travel or books theme TBD.</p>

        <p> It will be built using React and hosted using Azures static web app service.</p>

        <a
          className="App-link"
          href={productLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Goats
        </a>

      </header>
    </div>
  );
}

export default App;
